<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>视频号挂载数据</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 消息提示 -->
  <div class="alert-view">
    <a-alert
      type="warning"
      show-icon
    >
      <template #message>
        今日数据更新于 {{ refreshTime ? dayjs(refreshTime).format('HH:mm:ss') : '00:00:00' }}
        <a style="margin-left: 10px;" @click="refreshData">点击更新数据</a>
        <loading-outlined v-if="isRefreshData" style="margin-left: 6px;" />
      </template>
    </a-alert>
  </div>
  <!-- 功能条 -->
  <div class="tool-view">
    <a-row class="tool-row">
      <!-- 日期范围 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">日期范围：</a-col>
          <a-col :span="18">
            <a-range-picker
              class="tool-item"
              v-model:value="dateRangeModel"
              :format="dateFormat"
            />
          </a-col>
        </a-row>
      </a-col>
      <!-- 视频ID -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">视频ID：</a-col>
          <a-col :span="18">
            <a-input
              class="tool-item"
              v-model:value="videoIDModel"
              placeholder="请输入"
            />
          </a-col>
        </a-row>
      </a-col>
      <!-- 视频号 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">视频号：</a-col>
          <a-col :span="18">
            <a-select
              show-search
              class="tool-item"
              v-model:value="videoNumModel"
              placeholder="请选择"
              :filter-option="filterOption"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in videoNums" :key="`${item.wx_finder_id} - ${item.name}`" :value="item.wx_finder_id">{{ item.name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 服务商 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">服务商：</a-col>
          <a-col :span="18">
            <a-select
              class="tool-item"
              v-model:value="supplierModel"
              placeholder="请选择"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in suppliers" :key="item.provider_id" :value="item.provider_id">{{ item.provider_name }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 合并数据 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title">合并数据：</a-col>
          <a-col :span="18">
            <a-select
              class="tool-item"
              v-model:value="mergeTypeModel"
              placeholder="请选择"
            >
              <a-select-option :value="0">否</a-select-option>
              <a-select-option :value="1">是</a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <a-button type="primary" @click="touchSearch">查询</a-button>
            <a-button style="margin-left: 16px; margin-right: 16px;" @click="touchReset">重置</a-button>
          </a-col>
        </a-row>
      </a-col>
      <!-- 操作 -->
      <a-col :span="6">
        <a-row>
          <a-col :span="6" class="tool-title"></a-col>
          <a-col :span="18" style="white-space: nowrap;">
            <a-button @click="touchDownload">导出数据</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'export_id'">
        <div style="width: 200px;">{{ record[column.key] ? `...${record[column.key].slice(-20)}` : '-' }}</div>
      </template>
      <template v-else-if="column.key === 'total_income_amount'">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <template v-else>
        {{ record[column.key] || '-' }}
      </template>
    </template>
  </a-table>
  <!-- 自定义分页器 -->
  <a-pagination
    v-if="dataSource.length"
    style="margin: 16px 0; text-align: right;"
    v-model:current="pagination.current"
    v-model:pageSize="pagination.pageSize"
    :total="pagination.total"
    :showSizeChanger="pagination.showSizeChanger"
    @change="handlePaginationChange"
  />
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import * as Ex from "@netjoy/excelex"
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { videoFinderList, videoFinderRefreshVideoData, videoFinderRefreshTime, finderDarenAll, finderProviderAll } from '@/api/copyright'

// 更新时间
let refreshTime = ref('')
// 更新数据
let isRefreshData = ref(false)
// 加载
let isLoading = ref(false)
// 视频ID
let videoID = ref(undefined)
let videoIDModel = ref(undefined)
// 日期范围
const dateFormat = 'YYYY-MM-DD'
let dateRangeModel = ref([dayjs().subtract(30, 'day'), dayjs()])
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 合并数据
let mergeTypeModel = ref(0)
let mergeType = ref(0)
// 视频号
let videoNum = ref(undefined) 
let videoNumModel = ref(undefined) 
let videoNums = ref([])
// 服务商
let supplier = ref(undefined)
let supplierModel = ref(undefined)
let suppliers = ref([])
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: '视频ID',
    dataIndex: 'export_id',
    key: 'export_id'
  },
  {
    title: '视频号ID',
    dataIndex: 'wx_finder_id',
    key: 'wx_finder_id'
  },
  {
    title: '视频号名称',
    dataIndex: 'wx_finder_name',
    key: 'wx_finder_name'
  },
  {
    title: '撮合服务商',
    dataIndex: 'provider_name',
    key: 'provider_name'
  },
  {
    title: '充值金额',
    dataIndex: 'total_income_amount',
    key: 'total_income_amount'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 初始化选择项列表
  getSelectList()
  // 获取刷新时间
  getRefreshTime()
  // 获取推广数据列表
  getList()
})


// 获取刷新时间
function getRefreshTime () {
  videoFinderRefreshTime().then(res => {
    const { code, data } = res
    if (code === 0) {
      refreshTime.value = data.last_time || ''
    }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 分页处理
function handlePaginationChange (current, pageSize) {
  pagination.current = current
  pagination.pageSize = pageSize
  getList()
}

// 刷新接口
function refreshData () {
  if (isRefreshData.value) { return }
  isRefreshData.value = true
  videoFinderRefreshVideoData().then(res => {
    const { code, data } = res
    if (code === 0) {
      refreshTime.value = data.last_time || ''
      message.success('更新成功')
      touchSearch()
    }
    isRefreshData.value = false
  }).catch(() => {
    isRefreshData.value = false
  })
}

// 查询
function touchSearch () {
  // 调整参数
  videoID.value = videoIDModel.value
  dateRange.value = dateRangeModel.value
  mergeType.value = mergeTypeModel.value
  videoNum.value = videoNumModel.value
  supplier.value = supplierModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  dateRangeModel.value = [dayjs().subtract(30, 'day'), dayjs()]
  mergeTypeModel.value = 0
  mergeType.value = 0
  videoID.value = undefined
  videoIDModel.value = undefined
  videoNum.value = undefined
  videoNumModel.value = undefined
  supplier.value = undefined
  supplierModel.value = undefined
  // 初始化获取
  touchSearch()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    is_merge: mergeType.value,
    export_id: videoID.value,
    wx_finder_id: videoNum.value,
    provider_id: supplier.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  videoFinderList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data.concat([data.sumarry])
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 初始化选择项列表
function getSelectList () {
  finderDarenAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      videoNums.value = data
    }
  })
  finderProviderAll({ type: 1 }).then(res => {
    const { code, data } = res
    if (code === 0) {
      suppliers.value = data
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    is_merge: mergeType.value,
    export_id: videoID.value,
    wx_finder_id: videoNum.value,
    provider_id: supplier.value,
    page: 1,
    page_size: 100000
  }
  videoFinderList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 将汇总数据插入第一条
      const list = [].concat(data.data).concat([data.sumarry])
      if (list.length) {
        const columns = [
          {
            name: '日期',
            field: 'date'
          },
          {
            name: '视频ID',
            field: 'export_id'
          },
          {
            name: '视频号ID',
            field: 'wx_finder_id'
          },
          {
            name: '视频号名称',
            field: 'wx_finder_name'
          },
          {
            name: '撮合服务商',
            field: 'provider_name'
          },
          {
            name: '充值金额',
            field: 'total_income_amount'
          },
          {
            name: '姓名 ',
            field: 'real_name'
          },
          {
            name: '身份证',
            field: 'id_number'
          },
          {
            name: '银行卡号',
            field: 'bank_account'
          },
          {
            name: '手机号',
            field: 'phone'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额处理
            if (field === 'total_income_amount') {
              if (item.data != '-') {
                item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              }
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `运营管理-视频号挂载数据`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}
</script>

<style scoped>
.alert-view {
  margin-top: 20px;
}
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>